import request from "@/api/service";
import qs from 'qs'
const api = {
  // 获取 房间列表
  getRoomPage:'/hotel/room/getRoomPage',
  // 通过酒店ID获取 楼栋和单元的关联数据
  getBuildAndUnitList:'/hotel/build/getBuildAndUnitList',
}


//获取 房间列表
export function axios_getRoomPage(params,data){
  return request({
    url:api.getRoomPage,
    method:'post',
    params,
    data:qs.stringify(data),
  })
}

//通过酒店ID获取 楼栋和单元的关联数据
export function axios_getBuildAndUnitList(hotelId){
  return request({
    url:api.getBuildAndUnitList + `/${hotelId}`,
    method:'get',
  })
}
