import request from "@/api/service";
import qs from 'qs'

const api = {
   page: '/goods/productInfo/getExpandPage',
   select: '/hotel/getList',
   status: '/goods/productInfo/setStatus',
   create: '/goods/productInfo/create',
   modify: '/goods/productInfo/modify',
   upload: '/system/aliyun/oss/uploadImage',
   uploadThumbImage: 'system/aliyun/oss/uploadThumbImage',
   detail: '/goods/productInfo/getDetailInfo',
   delete: '/goods/productInfo/delete'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function selectHotel(params) {
   return request({
      url: api.select,
      method: 'POST',
      data: params
   })
}

export function setStatus(params) {
   return request({
      url: api.status,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function createGoods(params, action) {
   return request({
      url: api[action],
      method: 'POST',
      data: params
   })
}

export function uploadImage(params) {
   return request({
      url: api.upload,
      method: 'POST',
      data: params
   })
}

export function uploadThumbImage(params) {
   return request({
      url: api.uploadThumbImage,
      method: 'POST',
      data: params
   })
}

export function getDetail(query) {
   return request({
      url: api.detail + query,
      method: 'GET',
   })
}

export function modifyGoods(params) {
   return request({
      url: api.delete,
      method: 'POST',
      data: qs.stringify(params)
   })
}
