import request from "@/api/service";
import qs from 'qs'

const api ={
    // 创建 省电任务
    addElectricity:'/quartz/electricity/create',
    // 修改 省电任务
    editElectricity:'/quartz/electricity/update',
    // 查询任务详情
    getElectricityDetail:'/quartz/electricity/detail',
    // 产品列表
    getDeviceListByRoomType:'/device-hotel/device/getDeviceListByRoomType',

    // 获取房型
    getHotelRoomTypeList:"/hotel/roomtype/getHotelRoomTypeList",
}
// 新增/创建 省电助手
export function axios_addEditElectricity(data,state) {
  return request({
      url: state === 'add' ? api.addElectricity : api.editElectricity ,
      headers:{"responseType": 'JSON'},
      method:'post',
      data:data
  })
}
// 详情
export function axios_getElectricityDetail(data) {
  return request({
    url:api.getElectricityDetail+`/${data.id}`,
    method:'get',
    data:qs.stringify(data)
  })
}
// 查询 产品列表
export function axios_getDeviceListByRoomType(data) {
  return request({
      url:api.getDeviceListByRoomType,
      method:'post',
      data:qs.stringify(data)
  })
}
// 获取房型列表
export function axios_getHotelRoomTypeList(data) {
  return request({
    url:api.getHotelRoomTypeList,
    method:'post',
    data:qs.stringify(data)
  })
}






